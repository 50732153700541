import epilation from "../assets/images/prestation/epilation.jpg";
import main from "../assets/images/prestation/main.jpg";
import maquillage from "../assets/images/prestation/maquillage.jpg";
import modelage from "../assets/images/prestation/modelage.jpg";
import regard from "../assets/images/prestation/regard.jpg";
import visage from "../assets/images/prestation/visage.jpg";

const prestationImages = {
  epilation,
  main,
  maquillage,
  modelage,
  regard,
  visage,
};

export default prestationImages;
